/**
 * Returns true if the argument is null or undefined
 * otherwise returns false
 * @param ref
 * @returns
 */
function notNullish<T>(ref: T | null | undefined): ref is T {
  return ref !== null && ref !== undefined;
}

function isNullish(ref: unknown): ref is undefined | null {
  return ref === null || ref === undefined;
}

/**
 * Type guard used to determine if an EventTarget object is in fact a document Element.
 */
function isElement(target?: EventTarget | null): target is Element {
  return notNullish(target) && ((target as Element).childElementCount !== undefined);
}

/**
 * Returns the containing block for an HTMLElement that has a "fixed" position.
 * This is as per section 4 of https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block
 * @param element The element that has been set to "position: fixed" and for which we want
 *                to find the containing block.
 */
function getContainingBlockForFixed(element: HTMLElement): HTMLElement {
  let parent = element.parentElement;
  if (parent == null) {
    return element;
  } else {
    while (parent != null) {
      const stylesParent: CSSStyleDeclaration = getComputedStyle(parent);

      const backdropFilter = stylesParent.getPropertyValue('backdrop-filter');
      const hasBackdropFilter = !!backdropFilter && backdropFilter !== 'none';
      const wkBackdropFilter = stylesParent.getPropertyValue('-webkit-backdrop-filter'); // In use by Fender! (Safari)
      const hasWkBackdropFilter = !!wkBackdropFilter && wkBackdropFilter !== 'none';
      const filter = stylesParent.getPropertyValue('filter');
      const hasFilter = !!filter && filter !== 'none';
      const transform = stylesParent.getPropertyValue('transform');
      const hasTransform = !!transform && transform !== 'none';
      const perspective = stylesParent.getPropertyValue('perspective');
      const hasPerspective = !!perspective && perspective !== 'none';
      const contain = stylesParent.getPropertyValue('contain');
      const hasContain = !!contain && (contain === 'layout' || contain === 'paint' ||
          contain === 'strict' || contain === 'content');
      const containerType = stylesParent.getPropertyValue('container-type');
      const hasContainerType = !!containerType && containerType !== 'normal';
      const willChange = stylesParent.getPropertyValue('will-change');
      const hasWillChange = !!willChange && (willChange === 'filter' || willChange === 'transform');
      const contentVisibility = stylesParent.getPropertyValue('content-visibility');
      const hasContentVisibility = contentVisibility === 'auto';

      const isContainingBlock = hasBackdropFilter || hasWkBackdropFilter || hasFilter || hasTransform ||
          hasPerspective || hasContain || hasContainerType || hasWillChange || hasContentVisibility;

      if (isContainingBlock) {
        return parent;
      } else {
        parent = parent.parentElement;
      }
    }
  }
  return element.ownerDocument.body;
}

export {
  getContainingBlockForFixed,
  isElement,
  isNullish,
  notNullish,
};
