import mitt, { Emitter } from 'mitt';

import { SearchLocation } from '@/data/datatypes/SemanticSearchQuery';
import { Attribute } from '@/data/tasks/Attribute';
import { AttributeStyle } from '@/data/tasks/AttributeStyle';
import { CVSlideCarouselActionConfig } from '@/data/tasks/customviews/CustomViewEventHandlerConfig';
import { ThemeVariable } from '@/data/tasks/MiniAppViewTheme';
import { Task } from '@/data/tasks/Task';

export type CarouselEventPayload = {
  action: CVSlideCarouselActionConfig['slideDirection'],
  componentId: string,
  gotoIndex?: number,
}

type Events = {
  calendarEventDeleted: {eventId: string, entryId: string},
  rulesetEditsMade: {editsMade: boolean},
  openSearch: {location: SearchLocation},
  formattingChanged: {stylePatch: AttributeStyle},
  updateConditionalStyleForAttribute: {attribute: Attribute, task?: Task},
  clearPopupMenus: void,
  activePopupMenuHidden: {previousMenuId: string},
  openThemeManagementDialog: {themeVarId: string | null},
  addCustomThemeVariable: {value: ThemeVariable},
  carouselEvent: CarouselEventPayload,
  reloadCustomViewConfig: {json: string},
}

const appEventBus: Emitter<Events> = mitt();

export default appEventBus;
