import log from 'loglevel';

import { AppAuthResult } from '@/data/config/DataSource';
import DataWorker from '@/data/storage/DataWorker';
import { CustomViewUIConfig, Page } from '@/data/tasks/customviews/CustomViewUIConfig';
import { isNullish } from '@/util/util';

interface AppAuthToken {
  success: boolean;
  tokenId?: string;
  expiry?: number;
}

const appAuthPageTargets: Record<string, string> = {};

async function isValidAppAuthTokenPresent(appId: string, trackId?: string): Promise<boolean> {
  const existingAppAuthToken = localStorage.getItem(getAppAuthTokenLocalStorageKey(appId, trackId));
  if (existingAppAuthToken) {
    let clientServerTimeOffsetMillis = 0;

    const workerClientServerOffsetMillis =
      await DataWorker.instance().dispatch('OnlineStatus/getClientServerTimeOffset');

    if (workerClientServerOffsetMillis && Number(workerClientServerOffsetMillis)) {
      clientServerTimeOffsetMillis = Number(workerClientServerOffsetMillis);
    }
    try {
      const appAuthResult = JSON.parse(existingAppAuthToken) as AppAuthResult;
      if (appAuthResult && appAuthResult.success && appAuthResult.expiry) {
        const adjustedLocalTime = new Date().getTime() - clientServerTimeOffsetMillis;
        if (adjustedLocalTime <= appAuthResult.expiry) {
          return true;
        } else {
          localStorage.removeItem(getAppAuthTokenLocalStorageKey(appId, trackId));
        }
      }
    } catch (error) {
      log.error('Failed to parse app auth token to check validity');
      log.error(error);
    }
  }
  return false;
}

function getAppAuthTokenId(appId: string, trackId?: string): string | undefined {
  const existingAppAuthToken = localStorage.getItem(getAppAuthTokenLocalStorageKey(appId, trackId));
  if (existingAppAuthToken) {
    try {
      const token: AppAuthToken = JSON.parse(existingAppAuthToken);
      if (token && token.tokenId) {
        return token.tokenId;
      }
    } catch (error) {
      log.error('Failed to parse app auth token');
      log.error(error);
    }
  }
}

function isPageExcludedFromAuth(page: Page): boolean {
  const config = page.appAuthPageConfig;
  if (config) {
    return config.excludedFromAuth;
  }
  return false;
}

function getLoginPageIndex(uiModel: CustomViewUIConfig): number | undefined {
  if (uiModel) {
    for (let i = 0; i < uiModel.pages.length; i++) {
      if (uiModel.pages[i].pageType === 'login') {
        return i;
      }
    }
  }
}

function getPageIndexFromId(uiModel: CustomViewUIConfig, id: string): number | undefined {
  if (uiModel) {
    for (let i = 0; i < uiModel.pages.length; i++) {
      if (uiModel.pages[i].id === id) {
        return i;
      }
    }
  }
}

function getAppAuthTokenLocalStorageKey(appId: string, trackId?: string): string {
  if (trackId) {
    return `appAuthToken-${appId}-${trackId}`;
  }
  return `appAuthToken-${appId}`;
}

function getAppAuthTargetPageIdKey(appId: string, trackId?: string): string {
  if (trackId) {
    return `${appId}-${trackId}`;
  }
  return `${appId}`;
}

function storeAppAuthTargetPageId(appId: string, id: string, trackId?: string) {
  appAuthPageTargets[getAppAuthTargetPageIdKey(appId, trackId)] = id;
}

function getAppAuthTargetPageId(appId: string, trackId?: string) : string | null {
  const storedVal = appAuthPageTargets[getAppAuthTargetPageIdKey(appId, trackId)];
  return storedVal || null;
}

function removeAppAuthTargetPageId(appId: string, trackId?: string) {
  delete appAuthPageTargets[getAppAuthTargetPageIdKey(appId, trackId)];
}

function removeAppAuthTokenFromLocalStorage(appId: string, trackId?: string): void {
  localStorage.removeItem(getAppAuthTokenLocalStorageKey(appId, trackId));
}

function storeAppAuthTokenInLocalStorage(appId: string, trackId: string, appAuthResult: AppAuthResult): void {
  const trackIdToUse = appAuthResult.trackLevel ? trackId : undefined;
  localStorage.setItem(getAppAuthTokenLocalStorageKey(appId, trackIdToUse), JSON.stringify(appAuthResult));
}

function registerAppActivity(appId: string, trackId?: string): void {
  localStorage.setItem(getAppActivityLocalStorageKey(appId, trackId), new Date().getTime().toString());
}

function getAppActivityLocalStorageKey(appId: string, trackId?: string): string {
  if (isNullish(trackId)) {
    return `lastAppActivity-${appId}`;
  } else {
    return `lastAppActivity-${appId}-${trackId}`;
  }
}

function getLastAppActivityTime(appId: string, trackId?: string): number {
  const lastActivity = localStorage.getItem(getAppActivityLocalStorageKey(appId, trackId));
  if (isNullish(lastActivity)) {
    return 0;
  }
  return Number.parseInt(lastActivity) ?? 0;
}

export {
  getAppAuthTargetPageId,
  getAppAuthTokenId,
  getLastAppActivityTime,
  getLoginPageIndex,
  getPageIndexFromId,
  isPageExcludedFromAuth,
  isValidAppAuthTokenPresent,
  registerAppActivity,
  removeAppAuthTargetPageId,
  removeAppAuthTokenFromLocalStorage,
  storeAppAuthTargetPageId,
  storeAppAuthTokenInLocalStorage,
};
